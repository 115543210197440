import Home from '../pages/Home';
import My from '../pages/My';
import Order from '../pages/Order';
import Login from '../pages/Account/Login';
import Register from '../pages/Account/Register';
import History from '../pages/Order/History';
import EditPassword from '../pages/My/EditPassword';
import EditPwTransaction from '../pages/My/EditPwTransaction';
import MyBank from '../pages/My/MyBank';
import Withdraw from '../pages/My/Withdraw';
import Recharge from '../pages/My/Recharge';
import Address from '../pages/My/Address';
import FormRecharge from '../pages/My/FormRecharge';
import NotFound from '../pages/NotFound';
import Support from '../pages/My/Support';
import HistoryRechargeWithdraw from '../pages/Order/HistoryRechargeWithdraw';
import We from '../pages/Introduce/We';
import DieuKhoan from '../pages/Introduce/DieuKhoan';
import DefaultLayoutTwo from '../components/Layout/DefaultLayout/DefaultLayoutTwo';
import CauHoiThuongGap from '../pages/Introduce/CauHoiThuongGap';
import Invite from '../pages/My/Invite';
import Wallet from '../pages/My/Wallet';
import DefaultLayoutThree from '../components/Layout/DefaultLayout/DefaultLayoutThree';
import DefaultLayoutRegister from '../components/Layout/DefaultLayout/DefaultLayoutRegister';
import DefaultLayoutLogin from '../components/Layout/DefaultLayout/DLLogin';

// Public Router

let defaultTitle = 'VietNam Booking';

const publicRoutes = [
    // Trang chủ
    { path: '/', title: 'trangChu', component: Home },

    // Giới thiệu
    { path: '/about-us', title: 'veChungToi', component: We, layout: DefaultLayoutTwo },
    { path: '/term-conditions', title: 'DKvaDK', component: DieuKhoan, layout: DefaultLayoutTwo },
    { path: '/asked-questions', title: 'cauHoiTG', component: CauHoiThuongGap, layout: DefaultLayoutTwo },

    // Nạp rút tiền
    { path: '/recharge', title: 'napTien', component: Recharge, layout: DefaultLayoutThree },
    { path: '/recharge/:id', title: 'napTien', component: FormRecharge },
    { path: '/withdraw', title: 'rutTien', component: Withdraw, layout: DefaultLayoutThree },

    // Order
    { path: '/support', title: 'hoTroKH', component: Support },
    { path: '/order/mission', title: 'donHang', component: Order },
    { path: '/order/index', title: 'danhSachDH', component: History, layout: DefaultLayoutThree },

    // My
    { path: '/my', title: 'cuaToi', component: My },
    { path: '/my/wallet', title: 'viTien', component: Wallet },
    { path: '/my/invite', title: 'yeuCauDK', component: Invite, layout: DefaultLayoutThree },
    { path: '/my/address', title: 'diaChi', component: Address },
    {
        path: '/my/edit-password',
        title: 'doiMKDN',
        component: EditPassword,
        layout: DefaultLayoutThree,
    },
    { path: '/my/banking', title: 'tkNH', component: MyBank, layout: DefaultLayoutThree },
    {
        path: '/my/edit-password-transaction',
        title: 'doiMKRT',
        component: EditPwTransaction,
        layout: DefaultLayoutThree,
    },
    {
        path: '/my/history-recharge-withdraw',
        title: 'lichSuNapRut',
        component: HistoryRechargeWithdraw,
        layout: DefaultLayoutThree,
    },

    // Account
    {
        path: '/login',
        title: 'dangNhapTK',
        component: Login,
        layout: DefaultLayoutLogin,
    },
    { path: '/register', title: 'dangKyNgay', component: Register, layout: DefaultLayoutRegister },
    { path: '*', title: 'NotFound', component: NotFound, layout: null },
];
// Private Router
const privateRoutes = [];

export { publicRoutes, privateRoutes };
