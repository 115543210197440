import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logoTraveloka from '../../../../assets/images/sticker.webp';
import { FaRegListAlt } from 'react-icons/fa';

function Header(props) {
    return (
        <div
            className="fixed top-0 left-1/2 -translate-x-1/2 flex items-center justify-between w-[100%] max-w-[480px] px-4 z-50 py-2"
            style={{ background: 'url(/bg_header.png)', backgroundSize: '100% 100%' }}
        >
            <img src="/logo_vietnambooking.svg" alt="" className="!h-[60px]" />
            <Link to="/order/index">
                <FaRegListAlt color="white" size={24} />
            </Link>
        </div>
    );
}

export default memo(Header);
